/**
 * ClusterPinImages is meant to offer an accessible way to change the pin images for a cluster
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class ClusterPinImages {
    /**
     * @param {Object} defaultPinConfig The configuration for the default pin
     * @param {Object} hoveredPinConfig The configuration for the hovered pin
     * @param {Object} selectedPinConfig The configuration for the selected pin
     */
    constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
      this.defaultPinConfig = defaultPinConfig;
      this.hoveredPinConfig = hoveredPinConfig;
      this.selectedPinConfig = selectedPinConfig;
    }
  
    /**
     * Generate standard theme pin given some parameters
     * @param {string} pin.backgroundColor Background color for the pin
     * @param {string} pin.strokeColor Stroke (border) color for the pin
     * @param {string} pin.labelColor Label (text) color for the pin
     * @param {number} pin.width The width of the pin
     * @param {number} pin.height The height of the pin
     * @param {string} pin.labelText The label text for the cluster pin (normally size of cluster)
     * @return {Object} The SVG of the pin, and its width and height
     */
    generatePin ({
      svg = svg,
      width = 24,
      height= 24,
    } = {}) {
      return { svg, width, height };
    };
  
    /**
     * Get the default pin image
     * @param {Number} pinCount The number of pins in the cluster, for the pin label
     * @param {Object} profile The profile data for the entity associated with the pin
     */
    getDefaultPin (pinCount, profile) {
      let svgCars = `<svg width="51" height="66" viewBox="0 0 51 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.08 0C11.2415 0 0 10.7802 0 24.1066C0 33.0062 4.01307 44.238 13.9413 54.9529L15.8333 56.9591C18.0591 59.2794 20.3822 61.5636 23.1457 64.1733L25.08 66L26.9369 64.1733C30.49 60.818 33.3925 58.0008 36.218 54.9536C46.1469 44.238 50.16 33.0062 50.16 24.1066C50.16 10.7802 38.9185 0 25.08 0Z" fill="black"/>
                    <path d="M25.2156 5.41571C14.2828 5.41571 5.41565 14.2824 5.41565 25.2177C5.41565 36.1499 14.2836 45.0157 25.2156 45.0157C36.1477 45.0157 45.0156 36.1499 45.0156 25.2177C45.0156 14.2824 36.1485 5.41571 25.2156 5.41571Z" fill="white"/>
                    <path d="M25.2156 5.41571C14.2828 5.41571 5.41565 14.2824 5.41565 25.2177C5.41565 36.1499 14.2836 45.0157 25.2156 45.0157C36.1477 45.0157 45.0156 36.1499 45.0156 25.2177C45.0156 14.2824 36.1485 5.41571 25.2156 5.41571Z" stroke="black"/>
                    <path d="M25.2156 5.41571C14.2828 5.41571 5.41565 14.2824 5.41565 25.2177C5.41565 36.1499 14.2836 45.0157 25.2156 45.0157C36.1477 45.0157 45.0156 36.1499 45.0156 25.2177C45.0156 14.2824 36.1485 5.41571 25.2156 5.41571Z" stroke="black" stroke-opacity="0.2"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1203 13.2H18.0402C17.1193 13.2 16.5189 13.6836 16.0848 14.591L12.4857 21.5722C11.2926 21.6799 10.5601 22.4996 10.5601 23.7601V32.5603H12.3201V33.8803C12.3201 34.6789 12.8416 35.2003 13.6401 35.2003H16.2801C17.0785 35.2003 17.6001 34.6788 17.6001 33.8803V32.5603H32.5603V33.8803C32.5603 34.6789 33.0817 35.2003 33.8803 35.2003H36.5204C37.3189 35.2003 37.8404 34.6789 37.8404 33.8803V32.5603H39.6001V23.7601C39.6001 22.4996 38.8677 21.6799 37.6748 21.5722L34.0712 14.585C33.6423 13.6851 33.0432 13.2 32.1203 13.2ZM14.0801 26.1802C14.0801 27.517 15.1638 28.6002 16.5006 28.6002C17.8375 28.6002 18.9202 27.5172 18.9202 26.1802C18.9202 24.8429 17.8376 23.7601 16.5006 23.7601C15.1637 23.7601 14.0801 24.8431 14.0801 26.1802ZM33.6825 28.6002C32.3341 28.6002 31.2403 27.5184 31.2403 26.1802C31.2403 24.8417 32.334 23.7601 33.6825 23.7601C35.0299 23.7601 36.1244 24.8421 36.1244 26.1802C36.1244 27.518 35.0298 28.6002 33.6825 28.6002ZM34.7403 21.1191L31.8674 15.3988H18.3741L15.2822 21.1191H34.7403Z" fill="black"/>
                  </svg>`
  
      let svgCaravan =  `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 51 66" style="enable-background:new 0 0 51 66;" xml:space="preserve">
                        <style type="text/css">
                          .st0{fill:#FFFFFF;}
                          .st1{fill:none;stroke:#000000;}
                          .st2{fill:none;stroke:#000000;stroke-opacity:0.2;}
                        </style>
                        <path d="M25.1,0C11.2,0,0,10.8,0,24.1C0,33,4,44.2,13.9,55l1.9,2c2.2,2.3,4.5,4.6,7.3,7.2l1.9,1.8l1.9-1.8c3.6-3.4,6.5-6.2,9.3-9.2
                          C46.1,44.2,50.2,33,50.2,24.1C50.2,10.8,38.9,0,25.1,0z"/>
                        <path class="st0" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45C36.1,45,45,36.1,45,25.2
                          C45,14.3,36.1,5.4,25.2,5.4z"/>
                        <path class="st1" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45C36.1,45,45,36.1,45,25.2
                          C45,14.3,36.1,5.4,25.2,5.4z"/>
                        <path class="st2" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45C36.1,45,45,36.1,45,25.2
                          C45,14.3,36.1,5.4,25.2,5.4z"/>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path d="M36.5,23.1c0,0.3-0.4,0.6-0.9,0.6h-2c-0.5,0-0.9-0.3-0.9-0.6v-3.8c0-0.3,0.4-0.6,0.9-0.6h2c0.5,0,0.9,0.3,0.9,0.6V23.1z
                                  "/>
                                <path d="M27.6,29.1c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1C30.7,30.5,29.3,29.1,27.6,29.1z
                                  M27.6,34.1c-1.1,0-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9S28.7,34.1,27.6,34.1z"/>
                                <circle cx="27.6" cy="32.2" r="0.9"/>
                                <path d="M39.1,16.8c-0.6-1.1-1.6-1.6-2.8-1.8c-3.3-0.6-8.2-0.4-12.6,0.5c-2.7,0.6-4.9,1.8-6.6,4l-3.5,4.5
                                  c-1.3,1.7-1.5,4-0.5,5.9l0.7,1.3c0.1,0.2,0.2,0.4,0.4,0.5l-4.4,0.1l0-0.1c0-0.1,0.1-0.1,0.2-0.1l0.7-0.1c0,0,0.1,0,0.1-0.1
                                  c0,0,0-0.1-0.1-0.1H9.9c-0.1,0-0.3,0.1-0.3,0.2l-0.1,0.3l0,0c-0.3,0-0.6,0.2-0.7,0.5c0,0,0,0.1,0,0.1h7.1h0.6h7.1
                                  c0.2,0,0.4-0.2,0.4-0.4c0.2-1.8,1.7-3.2,3.6-3.2c1.8,0,3.4,1.4,3.6,3.2c0,0.2,0.2,0.4,0.5,0.4h7c1,0,1.8-0.6,2.2-1.5
                                  C42.9,25.3,40.6,19.6,39.1,16.8z M24.4,23.4H17c-0.3,0-0.5-0.4-0.3-0.6l2-2.6c0.7-0.9,1.8-1.5,3-1.5h2.6c0.3,0,0.6,0.3,0.6,0.6
                                  l0,3.6C24.9,23.2,24.7,23.4,24.4,23.4z M37.3,29.6c0,0.6-0.4,1-1,1h-3.4c-0.5,0-1-0.5-1-1V19c0-0.6,0.4-1,1-1h3.4
                                  c0.5,0,1,0.5,1,1V29.6z"/>
                              </g>
                              <g>
                                <path d="M12.9,33.6c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C13.7,34,13.4,33.6,12.9,33.6z
                                  M12.9,34.9c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5S13.1,34.9,12.9,34.9z"/>
                                <path d="M12.9,34.3l-1.1-0.5l0-3.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
                                  c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1,0l0,3.8l0,0c0,0.1,0,0.2,0.1,0.2
                                  l1.2,0.4c0.1,0,0.1,0,0.2-0.1C13.1,34.5,13,34.4,12.9,34.3z"/>
                              </g>
                            </g>
                          </g>
                          <circle cx="36.5" cy="25" r="0.5"/>
                        </g>
                        </svg>
                        `    
      let svgMotorCycle = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 51 66" style="enable-background:new 0 0 51 66;" xml:space="preserve">
                            <style type="text/css">
                              .st0{fill:#FFFFFF;}
                              .st1{fill:none;stroke:#000000;}
                              .st2{fill:none;stroke:#000000;stroke-opacity:0.2;}
                            </style>
                            <path d="M25.1,0C11.2,0,0,10.8,0,24.1C0,33,4,44.2,13.9,55l1.9,2c2.2,2.3,4.5,4.6,7.3,7.2l1.9,1.8l1.9-1.8c3.6-3.4,6.5-6.2,9.3-9.2
                              C46.1,44.2,50.2,33,50.2,24.1C50.2,10.8,38.9,0,25.1,0z"/>
                            <path class="st0" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45C36.1,45,45,36.1,45,25.2
                              C45,14.3,36.1,5.4,25.2,5.4z"/>
                            <path class="st1" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45C36.1,45,45,36.1,45,25.2
                              C45,14.3,36.1,5.4,25.2,5.4z"/>
                            <path class="st2" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45C36.1,45,45,36.1,45,25.2
                              C45,14.3,36.1,5.4,25.2,5.4z"/>
                            <path d="M35.8,24.1c-1.4,0-2.6,0.6-3.5,1.5l-0.5-0.5l2.8-2.5l1.6-0.4c0.4-0.1,0.8,0,1.1,0.1l3.5,1.7l-1.1-2v-1.6
                              c0-0.3-0.2-0.5-0.5-0.5h-0.8l-0.6-0.6c0,0-2.6,0.1-3.8,0.2c-1.2,0.1-0.8,0.4-2.3,1.2c-1.5,0.8-2.7,0-3.4,0.2c0-1.2-2.5-2.5-3.9-2.7
                              c-1.5-0.3-3,0.1-3.7,0.5l-0.5,1l-0.7-0.4l0.8-1.6l1.3-0.2c0.3-0.1,0.6-0.4,0.5-0.7c-0.1-0.3-0.4-0.6-0.7-0.5l-1.9,0.3L18.9,18
                              l-1.2-1c-2.3,0.5-2.3,3.5-1.7,4.5l0.8,0.1l-0.3,0.5l-0.2,1l-0.3,0.7c-0.6-0.2-1.3-0.4-1.9-0.4c-1.1,0-2.2,0.3-3,0.9l0.5,0.5
                              c-1.3,0.8-2.2,2.3-2.2,4c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8c0-0.3,0-0.6-0.1-0.9h0.7c-0.2-1.3-0.9-2.4-1.8-3.2l-1.2,1
                              l0.9-1.7l0.9-1l0.2-0.5L20,26c-0.1,0.2-0.2,0.4-0.1,0.7l1.4,2.8c0.3,0.7,1,1.1,1.8,1.1h5.6c0.3,0,0.6-0.1,0.9-0.2l4-1.6l1.9,1
                              c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.6-0.2,0.8-0.5c0.2-0.4,0-1-0.4-1.2l-2.6-1.3c0.5-0.6,1.3-0.9,2.2-0.9c1.7,0,3,1.3,3,3s-1.3,3-3,3
                              c-1.6,0-2.9-1.2-3-2.8l-1.5,1.2c0.6,1.9,2.4,3.3,4.5,3.3c2.6,0,4.8-2.1,4.8-4.8S38.4,24.1,35.8,24.1z M16.9,28.9c0,1.7-1.3,3-3,3
                              c-1.7,0-3-1.3-3-3s1.3-3,3-3c0.3,0,0.5,0,0.8,0.1l-1.4,2.6c-0.2,0.3,0,0.7,0.3,0.9c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3
                              l1.4-2.6C16.5,27.1,16.9,27.9,16.9,28.9z M28.8,29.2h-1.4l2.1-1.2h1.5L28.8,29.2z"/>
                            </svg>
                          `
      let svgSrc = svgCars;
      switch(profile.c_dealerCategory){
        case 'LEISURE' : svgSrc = svgCaravan;break;
        case 'MOTORCYCLES' : svgSrc = svgMotorCycle;break;
      }
      return this.generatePin({
        svg : svgSrc
      });
    }
  
    /**
     * Get the hovered pin image
     * @param {Number} pinCount The number of pins in the cluster, for the pin label
     * @param {Object} profile The profile data for the entity associated with the pin
     */
    getHoveredPin (pinCount, profile) {
      let svgCars = `<svg width="51" height="66" viewBox="0 0 51 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.08 0C11.2415 0 0 10.7802 0 24.1066C0 33.0062 4.01307 44.238 13.9413 54.9529L15.8333 56.9591C18.0591 59.2794 20.3822 61.5636 23.1457 64.1733L25.08 66L26.9369 64.1733C30.49 60.818 33.3925 58.0008 36.218 54.9536C46.1469 44.238 50.16 33.0062 50.16 24.1066C50.16 10.7802 38.9185 0 25.08 0Z" fill="#107EB1"/>
                      <path d="M25.2156 5.41571C14.2828 5.41571 5.41565 14.2824 5.41565 25.2177C5.41565 36.1499 14.2836 45.0157 25.2156 45.0157C36.1477 45.0157 45.0156 36.1499 45.0156 25.2177C45.0156 14.2824 36.1485 5.41571 25.2156 5.41571Z" fill="white" stroke="#107EB1"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1203 13.2H18.0402C17.1193 13.2 16.5189 13.6836 16.0848 14.591L12.4857 21.5722C11.2926 21.6799 10.5601 22.4996 10.5601 23.7601V32.5603H12.3201V33.8803C12.3201 34.6789 12.8416 35.2003 13.6401 35.2003H16.2801C17.0785 35.2003 17.6001 34.6788 17.6001 33.8803V32.5603H32.5603V33.8803C32.5603 34.6789 33.0817 35.2003 33.8803 35.2003H36.5204C37.3189 35.2003 37.8404 34.6789 37.8404 33.8803V32.5603H39.6001V23.7601C39.6001 22.4996 38.8677 21.6799 37.6748 21.5722L34.0712 14.585C33.6423 13.6851 33.0432 13.2 32.1203 13.2ZM14.0801 26.1802C14.0801 27.517 15.1638 28.6002 16.5006 28.6002C17.8375 28.6002 18.9202 27.5172 18.9202 26.1802C18.9202 24.8429 17.8376 23.7601 16.5006 23.7601C15.1637 23.7601 14.0801 24.8431 14.0801 26.1802ZM33.6825 28.6002C32.3341 28.6002 31.2403 27.5184 31.2403 26.1802C31.2403 24.8417 32.334 23.7601 33.6825 23.7601C35.0299 23.7601 36.1244 24.8421 36.1244 26.1802C36.1244 27.518 35.0298 28.6002 33.6825 28.6002ZM34.7403 21.1191L31.8674 15.3988H18.3741L15.2822 21.1191H34.7403Z" fill="#107EB1"/>
                    </svg>`
  
      let svgCaravan =  `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 51 66" style="enable-background:new 0 0 51 66;" xml:space="preserve">
                        <style type="text/css">
                          .st0{fill:#107EB1;}
                          .st1{fill:#FFFFFF;}
                        </style>
                        <path class="st0" d="M25.1,0C11.2,0,0,10.8,0,24.1C0,33,4,44.2,13.9,55l1.9,2c2.2,2.3,4.5,4.6,7.3,7.2L25,66l1.9-1.8
                          c3.6-3.4,6.5-6.2,9.3-9.2c9.9-10.8,14-22,14-30.9C50.2,10.8,38.9,0,25.1,0z"/>
                        <path class="st1" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45S45,36.1,45,25.2S36.1,5.4,25.2,5.4z"/>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path class="st0" d="M36.5,23.1c0,0.3-0.4,0.6-0.9,0.6h-2c-0.5,0-0.9-0.3-0.9-0.6v-3.8c0-0.3,0.4-0.6,0.9-0.6h2
                                  c0.5,0,0.9,0.3,0.9,0.6V23.1z"/>
                                <path class="st0" d="M27.6,29.1c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1C30.7,30.5,29.3,29.1,27.6,29.1z
                                  M27.6,34.1c-1.1,0-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9S28.7,34.1,27.6,34.1z"/>
                                <circle class="st0" cx="27.6" cy="32.2" r="0.9"/>
                                <path class="st0" d="M39.1,16.8c-0.6-1.1-1.6-1.6-2.8-1.8c-3.3-0.6-8.2-0.4-12.6,0.5c-2.7,0.6-4.9,1.8-6.6,4L13.6,24
                                  c-1.3,1.7-1.5,4-0.5,5.9l0.7,1.3c0.1,0.2,0.2,0.4,0.4,0.5l-4.4,0.1v-0.1c0-0.1,0.1-0.1,0.2-0.1l0.7-0.1c0,0,0.1,0,0.1-0.1
                                  c0,0,0-0.1-0.1-0.1H9.9c-0.1,0-0.3,0.1-0.3,0.2l-0.1,0.3l0,0c-0.3,0-0.6,0.2-0.7,0.5v0.1h7.1h0.6h7.1c0.2,0,0.4-0.2,0.4-0.4
                                  c0.2-1.8,1.7-3.2,3.6-3.2c1.8,0,3.4,1.4,3.6,3.2c0,0.2,0.2,0.4,0.5,0.4h7c1,0,1.8-0.6,2.2-1.5C42.9,25.3,40.6,19.6,39.1,16.8z
                                  M24.4,23.4H17c-0.3,0-0.5-0.4-0.3-0.6l2-2.6c0.7-0.9,1.8-1.5,3-1.5h2.6c0.3,0,0.6,0.3,0.6,0.6v3.6
                                  C24.9,23.2,24.7,23.4,24.4,23.4z M37.3,29.6c0,0.6-0.4,1-1,1h-3.4c-0.5,0-1-0.5-1-1V19c0-0.6,0.4-1,1-1h3.4c0.5,0,1,0.5,1,1
                                  V29.6z"/>
                              </g>
                              <g>
                                <path class="st0" d="M12.9,33.6c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8S13.4,33.6,12.9,33.6z
                                  M12.9,34.9c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S13.1,34.9,12.9,34.9z"/>
                                <path class="st0" d="M12.9,34.3l-1.1-0.5v-3.7h0.1c0,0,0.1,0,0.1-0.1s0-0.2-0.1-0.2h-0.1h-0.1h-0.1h-0.1c0,0-0.1,0-0.1,0.1
                                  s0,0.2,0.1,0.2h0.1v3.8l0,0c0,0.1,0,0.2,0.1,0.2l1.2,0.4c0.1,0,0.1,0,0.2-0.1C13.1,34.5,13,34.4,12.9,34.3z"/>
                              </g>
                            </g>
                          </g>
                          <circle class="st0" cx="36.5" cy="25" r="0.5"/>
                        </g>
                        </svg>
                        `    
      let svgMotorCycle = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 51 66" style="enable-background:new 0 0 51 66;" xml:space="preserve">
                          <style type="text/css">
                            .st0{fill:#107EB1;}
                            .st1{fill:#FFFFFF;}
                          </style>
                          <path class="st0" d="M25.1,0C11.2,0,0,10.8,0,24.1C0,33,4,44.2,13.9,55l1.9,2c2.2,2.3,4.5,4.6,7.3,7.2L25,66l1.9-1.8
                            c3.6-3.4,6.5-6.2,9.3-9.2c9.9-10.8,14-22,14-30.9C50.2,10.8,38.9,0,25.1,0z"/>
                          <path class="st1" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45S45,36.1,45,25.2S36.1,5.4,25.2,5.4z"/>
                          <path class="st0" d="M35.8,24.1c-1.4,0-2.6,0.6-3.5,1.5l-0.5-0.5l2.8-2.5l1.6-0.4c0.4-0.1,0.8,0,1.1,0.1l3.5,1.7l-1.1-2v-1.6
                            c0-0.3-0.2-0.5-0.5-0.5h-0.8l-0.6-0.6c0,0-2.6,0.1-3.8,0.2s-0.8,0.4-2.3,1.2c-1.5,0.8-2.7,0-3.4,0.2c0-1.2-2.5-2.5-3.9-2.7
                            c-1.5-0.3-3,0.1-3.7,0.5l-0.5,1l-0.7-0.4l0.8-1.6l1.3-0.2c0.3-0.1,0.6-0.4,0.5-0.7c-0.1-0.3-0.4-0.6-0.7-0.5l-1.9,0.3L18.9,18
                            l-1.2-1c-2.3,0.5-2.3,3.5-1.7,4.5l0.8,0.1l-0.3,0.5l-0.2,1L16,23.8c-0.6-0.2-1.3-0.4-1.9-0.4c-1.1,0-2.2,0.3-3,0.9l0.5,0.5
                            c-1.3,0.8-2.2,2.3-2.2,4c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8c0-0.3,0-0.6-0.1-0.9h0.7c-0.2-1.3-0.9-2.4-1.8-3.2l-1.2,1
                            l0.9-1.7l0.9-1l0.2-0.5L20,26c-0.1,0.2-0.2,0.4-0.1,0.7l1.4,2.8c0.3,0.7,1,1.1,1.8,1.1h5.6c0.3,0,0.6-0.1,0.9-0.2l4-1.6l1.9,1
                            c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.6-0.2,0.8-0.5c0.2-0.4,0-1-0.4-1.2l-2.6-1.3c0.5-0.6,1.3-0.9,2.2-0.9c1.7,0,3,1.3,3,3s-1.3,3-3,3
                            c-1.6,0-2.9-1.2-3-2.8l-1.5,1.2c0.6,1.9,2.4,3.3,4.5,3.3c2.6,0,4.8-2.1,4.8-4.8S38.4,24.1,35.8,24.1z M16.9,28.9c0,1.7-1.3,3-3,3
                            s-3-1.3-3-3s1.3-3,3-3c0.3,0,0.5,0,0.8,0.1l-1.4,2.6c-0.2,0.3,0,0.7,0.3,0.9c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3l1.4-2.6
                            C16.5,27.1,16.9,27.9,16.9,28.9z M28.8,29.2h-1.4l2.1-1.2H31L28.8,29.2z"/>
                          </svg>
                          `
      let svgSrc = svgCars;
      switch(profile.c_dealerCategory){
        case 'LEISURE' : svgSrc = svgCaravan;break;
        case 'MOTORCYCLES' : svgSrc = svgMotorCycle;break;
      }
  
      return this.generatePin({
        svg : svgSrc
      });
    }
  
    /**
     * Get the selected pin image
     * @param {Number} pinCount The number of pins in the cluster, for the pin label
     * @param {Object} profile The profile data for the entity associated with the pin
     */
    getSelectedPin (pinCount, profile) {
      let svgCars = `<svg width="51" height="66" viewBox="0 0 51 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.08 0C11.2415 0 0 10.7802 0 24.1066C0 33.0062 4.01307 44.238 13.9413 54.9529L15.8333 56.9591C18.0591 59.2794 20.3822 61.5636 23.1457 64.1733L25.08 66L26.9369 64.1733C30.49 60.818 33.3925 58.0008 36.218 54.9536C46.1469 44.238 50.16 33.0062 50.16 24.1066C50.16 10.7802 38.9185 0 25.08 0Z" fill="#107EB1"/>
                      <path d="M25.2156 5.41571C14.2828 5.41571 5.41565 14.2824 5.41565 25.2177C5.41565 36.1499 14.2836 45.0157 25.2156 45.0157C36.1477 45.0157 45.0156 36.1499 45.0156 25.2177C45.0156 14.2824 36.1485 5.41571 25.2156 5.41571Z" fill="white" stroke="#107EB1"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1203 13.2H18.0402C17.1193 13.2 16.5189 13.6836 16.0848 14.591L12.4857 21.5722C11.2926 21.6799 10.5601 22.4996 10.5601 23.7601V32.5603H12.3201V33.8803C12.3201 34.6789 12.8416 35.2003 13.6401 35.2003H16.2801C17.0785 35.2003 17.6001 34.6788 17.6001 33.8803V32.5603H32.5603V33.8803C32.5603 34.6789 33.0817 35.2003 33.8803 35.2003H36.5204C37.3189 35.2003 37.8404 34.6789 37.8404 33.8803V32.5603H39.6001V23.7601C39.6001 22.4996 38.8677 21.6799 37.6748 21.5722L34.0712 14.585C33.6423 13.6851 33.0432 13.2 32.1203 13.2ZM14.0801 26.1802C14.0801 27.517 15.1638 28.6002 16.5006 28.6002C17.8375 28.6002 18.9202 27.5172 18.9202 26.1802C18.9202 24.8429 17.8376 23.7601 16.5006 23.7601C15.1637 23.7601 14.0801 24.8431 14.0801 26.1802ZM33.6825 28.6002C32.3341 28.6002 31.2403 27.5184 31.2403 26.1802C31.2403 24.8417 32.334 23.7601 33.6825 23.7601C35.0299 23.7601 36.1244 24.8421 36.1244 26.1802C36.1244 27.518 35.0298 28.6002 33.6825 28.6002ZM34.7403 21.1191L31.8674 15.3988H18.3741L15.2822 21.1191H34.7403Z" fill="#107EB1"/>
                    </svg>`
  
      let svgCaravan =  `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 51 66" style="enable-background:new 0 0 51 66;" xml:space="preserve">
                        <style type="text/css">
                          .st0{fill:#107EB1;}
                          .st1{fill:#FFFFFF;}
                        </style>
                        <path class="st0" d="M25.1,0C11.2,0,0,10.8,0,24.1C0,33,4,44.2,13.9,55l1.9,2c2.2,2.3,4.5,4.6,7.3,7.2L25,66l1.9-1.8
                          c3.6-3.4,6.5-6.2,9.3-9.2c9.9-10.8,14-22,14-30.9C50.2,10.8,38.9,0,25.1,0z"/>
                        <path class="st1" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45S45,36.1,45,25.2S36.1,5.4,25.2,5.4z"/>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path class="st0" d="M36.5,23.1c0,0.3-0.4,0.6-0.9,0.6h-2c-0.5,0-0.9-0.3-0.9-0.6v-3.8c0-0.3,0.4-0.6,0.9-0.6h2
                                  c0.5,0,0.9,0.3,0.9,0.6V23.1z"/>
                                <path class="st0" d="M27.6,29.1c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1C30.7,30.5,29.3,29.1,27.6,29.1z
                                  M27.6,34.1c-1.1,0-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9S28.7,34.1,27.6,34.1z"/>
                                <circle class="st0" cx="27.6" cy="32.2" r="0.9"/>
                                <path class="st0" d="M39.1,16.8c-0.6-1.1-1.6-1.6-2.8-1.8c-3.3-0.6-8.2-0.4-12.6,0.5c-2.7,0.6-4.9,1.8-6.6,4L13.6,24
                                  c-1.3,1.7-1.5,4-0.5,5.9l0.7,1.3c0.1,0.2,0.2,0.4,0.4,0.5l-4.4,0.1v-0.1c0-0.1,0.1-0.1,0.2-0.1l0.7-0.1c0,0,0.1,0,0.1-0.1
                                  c0,0,0-0.1-0.1-0.1H9.9c-0.1,0-0.3,0.1-0.3,0.2l-0.1,0.3l0,0c-0.3,0-0.6,0.2-0.7,0.5v0.1h7.1h0.6h7.1c0.2,0,0.4-0.2,0.4-0.4
                                  c0.2-1.8,1.7-3.2,3.6-3.2c1.8,0,3.4,1.4,3.6,3.2c0,0.2,0.2,0.4,0.5,0.4h7c1,0,1.8-0.6,2.2-1.5C42.9,25.3,40.6,19.6,39.1,16.8z
                                  M24.4,23.4H17c-0.3,0-0.5-0.4-0.3-0.6l2-2.6c0.7-0.9,1.8-1.5,3-1.5h2.6c0.3,0,0.6,0.3,0.6,0.6v3.6
                                  C24.9,23.2,24.7,23.4,24.4,23.4z M37.3,29.6c0,0.6-0.4,1-1,1h-3.4c-0.5,0-1-0.5-1-1V19c0-0.6,0.4-1,1-1h3.4c0.5,0,1,0.5,1,1
                                  V29.6z"/>
                              </g>
                              <g>
                                <path class="st0" d="M12.9,33.6c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8S13.4,33.6,12.9,33.6z
                                  M12.9,34.9c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S13.1,34.9,12.9,34.9z"/>
                                <path class="st0" d="M12.9,34.3l-1.1-0.5v-3.7h0.1c0,0,0.1,0,0.1-0.1s0-0.2-0.1-0.2h-0.1h-0.1h-0.1h-0.1c0,0-0.1,0-0.1,0.1
                                  s0,0.2,0.1,0.2h0.1v3.8l0,0c0,0.1,0,0.2,0.1,0.2l1.2,0.4c0.1,0,0.1,0,0.2-0.1C13.1,34.5,13,34.4,12.9,34.3z"/>
                              </g>
                            </g>
                          </g>
                          <circle class="st0" cx="36.5" cy="25" r="0.5"/>
                        </g>
                        </svg>
                        `    
      let svgMotorCycle = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 51 66" style="enable-background:new 0 0 51 66;" xml:space="preserve">
                          <style type="text/css">
                            .st0{fill:#107EB1;}
                            .st1{fill:#FFFFFF;}
                          </style>
                          <path class="st0" d="M25.1,0C11.2,0,0,10.8,0,24.1C0,33,4,44.2,13.9,55l1.9,2c2.2,2.3,4.5,4.6,7.3,7.2L25,66l1.9-1.8
                            c3.6-3.4,6.5-6.2,9.3-9.2c9.9-10.8,14-22,14-30.9C50.2,10.8,38.9,0,25.1,0z"/>
                          <path class="st1" d="M25.2,5.4c-10.9,0-19.8,8.9-19.8,19.8C5.4,36.1,14.3,45,25.2,45S45,36.1,45,25.2S36.1,5.4,25.2,5.4z"/>
                          <path class="st0" d="M35.8,24.1c-1.4,0-2.6,0.6-3.5,1.5l-0.5-0.5l2.8-2.5l1.6-0.4c0.4-0.1,0.8,0,1.1,0.1l3.5,1.7l-1.1-2v-1.6
                            c0-0.3-0.2-0.5-0.5-0.5h-0.8l-0.6-0.6c0,0-2.6,0.1-3.8,0.2s-0.8,0.4-2.3,1.2c-1.5,0.8-2.7,0-3.4,0.2c0-1.2-2.5-2.5-3.9-2.7
                            c-1.5-0.3-3,0.1-3.7,0.5l-0.5,1l-0.7-0.4l0.8-1.6l1.3-0.2c0.3-0.1,0.6-0.4,0.5-0.7c-0.1-0.3-0.4-0.6-0.7-0.5l-1.9,0.3L18.9,18
                            l-1.2-1c-2.3,0.5-2.3,3.5-1.7,4.5l0.8,0.1l-0.3,0.5l-0.2,1L16,23.8c-0.6-0.2-1.3-0.4-1.9-0.4c-1.1,0-2.2,0.3-3,0.9l0.5,0.5
                            c-1.3,0.8-2.2,2.3-2.2,4c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8c0-0.3,0-0.6-0.1-0.9h0.7c-0.2-1.3-0.9-2.4-1.8-3.2l-1.2,1
                            l0.9-1.7l0.9-1l0.2-0.5L20,26c-0.1,0.2-0.2,0.4-0.1,0.7l1.4,2.8c0.3,0.7,1,1.1,1.8,1.1h5.6c0.3,0,0.6-0.1,0.9-0.2l4-1.6l1.9,1
                            c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.6-0.2,0.8-0.5c0.2-0.4,0-1-0.4-1.2l-2.6-1.3c0.5-0.6,1.3-0.9,2.2-0.9c1.7,0,3,1.3,3,3s-1.3,3-3,3
                            c-1.6,0-2.9-1.2-3-2.8l-1.5,1.2c0.6,1.9,2.4,3.3,4.5,3.3c2.6,0,4.8-2.1,4.8-4.8S38.4,24.1,35.8,24.1z M16.9,28.9c0,1.7-1.3,3-3,3
                            s-3-1.3-3-3s1.3-3,3-3c0.3,0,0.5,0,0.8,0.1l-1.4,2.6c-0.2,0.3,0,0.7,0.3,0.9c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.6-0.3l1.4-2.6
                            C16.5,27.1,16.9,27.9,16.9,28.9z M28.8,29.2h-1.4l2.1-1.2H31L28.8,29.2z"/>
                          </svg>
                          `
      let svgSrc = svgCars;
      switch(profile.c_dealerCategory){
        case 'LEISURE' : svgSrc = svgCaravan;break;
        case 'MOTORCYCLES' : svgSrc = svgMotorCycle;break;
      }
  
      return this.generatePin({
        svg : svgSrc
      });
    }
  }
  
  export { ClusterPinImages };